<div class="flex">
  <div class="flex1" nz-row>
    <div nz-col nzSm="5">
      <div class="label">Transit Cost</div>
      <div class="medium">{{displayInfo.transitCost}}</div>
    </div>
    <div nz-col nzSm="4">
      <div class="label">Fuel Cost</div>
      <div class="medium">{{displayInfo.fuelCost}}</div>
    </div>
    <div nz-col nzSm="5">
      <div class="label">Service Cost</div>
      <div class="medium">{{displayInfo.serviceCost}}</div>
    </div>
    <div nz-col nzSm="5">
      <div class="label">Pending Deductions</div>
      <div class="medium">{{displayInfo.pendingServiceCost}}</div>
    </div>
    <div nz-col nzSm="5">
      <div class="bold label">Grand Total</div>
      <div class="bold">{{displayInfo.grandTotal}}</div>
    </div>
  </div>
  <div *ngIf="displayInfo.isCarrierRequiredBill">
    <div class="bold label">Carrier Bill Matches</div>
    <div class="medium">{{displayInfo.isCarrierBillMatches.toString().toUpperCase()}}</div>
  </div>
  <div style="margin-left: 3em;">
    <button nz-button class="btn-edit-carrier-cost" (click)="onBtnEditCarrierCost()" [disabled]="isReadOnly">Edit</button>
  </div>
</div>
<div *ngIf="arrServiceOptions.length > 0" class="serviceOptionContainer">
  <div class="bold">Service Options:</div>
  <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: -15px;">
    <div nz-col nzSm="12" nzMd="12"></div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Rate</div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Quantity</div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Total</div>
  </div>
  <div *ngFor="let item of arrServiceOptions" nz-row [nzGutter]="{ sm: 16 }" class="row-service-options">
    <div nz-col nzSm="12" nzMd="12">
      <span>{{item.name}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.rate}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.qty}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.total}}</span>
    </div>
  </div>
</div>

<div *ngIf="arrPendingServiceOptions.length > 0"  class="serviceOptionContainer">
  <div class="bold">Pending Deductions:</div>
  <div nz-row [nzGutter]="{ sm: 16 }" style="margin-top: -15px;">
    <div nz-col nzSm="12" nzMd="12"></div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Rate</div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Quantity</div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">Total</div>
  </div>
  <div *ngFor="let item of arrPendingServiceOptions" nz-row [nzGutter]="{ sm: 16 }" class="row-service-options">
    <div nz-col nzSm="12" nzMd="12">
      <span>{{item.name}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.rate}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.qty}}</span>
    </div>
    <div nz-col nzSm="4" nzMd="4" class="col-ser-opt">
      <span>{{item.total}}</span>
    </div>
  </div>
</div>

<div *ngIf="shouldShowCarrierInvoice">
  <div *ngIf="!isAdditionalCarrierCost" class="separator h top15 bottom15"></div>
  <div *ngIf="isAdditionalCarrierCost" class="top10"></div>
  <div class="flex">
    <div class="flex1">
      <span><img src="/assets/img/dispatch-icons/description.svg" /></span>
      <span class="document-label">Carrier Invoice</span>
    </div>
    <div class="document-action" (click)="onBtnViewCarrierInvoice()">
      <img *ngIf="!isLoadingCarrierInvoice" src="/assets/img/dispatch-icons/visibility.svg" />
      <i *ngIf="isLoadingCarrierInvoice" nz-icon nzType="loading" nzTheme="outline"></i>
      <span class="label">View</span>
    </div>
    <div class="document-action" style="margin-left: 50px;" (click)="onBtnDownloadCarrierInvoice()">
      <img *ngIf="!isLoadingCarrierInvoice" src="/assets/img/dispatch-icons/download.svg" />
      <i *ngIf="isLoadingCarrierInvoice" nz-icon nzType="loading" nzTheme="outline"></i>
      <span class="label">Download</span>
    </div>
  </div>
</div>
<div *ngIf="isAccounting && firstShipmentCode" class="top10">
  <a [href]="getLinkSearchDenimJob()" target="_blank" class="link f13">
    <i nz-icon nzType="search" nzTheme="outline" class="right5"></i>Check Denim Job
  </a>
</div>

<ng-container *ngIf="shouldShowCheckbox_alreadyPaid">
  <div *ngIf="!isAdditionalCarrierCost" class="separator h top15 bottom15"></div>
  <div style="display: flex;" [class]="{'top20': isAdditionalCarrierCost}">
    <div nz-checkbox 
      [nzDisabled]="isMarkingCarrierPaid || shouldDisableMarkAlreadyPaid || !requirePermissions([PermissionCode.payment.mark_as_paid])" 
      [(ngModel)]="checkBoxValue_alreadyPaid" 
      (ngModelChange)="onCheckboxChange_AlreadyPaid($event)">Already paid</div>
    <i *ngIf="isMarkingCarrierPaid" nz-icon nzType="loading" nzTheme="outline"></i>
    <warp-when-by *ngIf="carrierCost?.paid?.when" style="flex: 1;"
      [model]="carrierCost?.paid" 
      [viewTemplate]="tplPaidBy">
    </warp-when-by>
    <button *ngIf="carrierCost?.paid?.when && requirePermissions([PermissionCode.payment.note_as_paid]) " nz-button (click)="onBtnNoteCarrierPaid()">
      <i nz-icon nzType="edit" nzTheme="outline"></i>
      Note
    </button>
  </div>
  <div *ngIf="carrierCost?.paid?.note" class="top10" style="white-space: pre;">{{carrierCost?.paid?.note}}</div>
  <div *ngIf="carrierCost?.paid?.transferDate" class="top10">Transfer date: {{displayDateDB(carrierCost?.paid?.transferDate)}}</div>
</ng-container>

<ng-template #tplPaidBy let-displayInfo="displayInfo" let-hasData="hasData">
  <span *ngIf="hasData" class="grey f13" style="font-style: italic">{{displayInfo.createdWhen}} by 
    <a [routerLink]="[displayInfo.hyperLinkCreatedBy]" class="grey" target="_blank">{{displayInfo.createdBy}}</a>
  </span>
</ng-template>
